<template>
  <div class="container">
    <DesktopSteps
      :steps="steps"
      :last="lastStep"
      :selected="stepSelected"
      :disabled="stepsDisabled"
      :onSelect="onSelectStep"
    />
    <input id="upload-detail" class="upload" type="file" />
    <input id="upload-movement" class="upload" type="file" />
    <!-- Introduccion -->
    <div v-if="stepSelected === 1" class="container-form">
      <BasicTitle title="Introducción" />
      <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      />
      <FormError />
      <BasicLabel label="Mes *" />
      <BorderSelect
        v-model="step1.data.month"
        label="Mes *"
        :options="step1.months"
        :disabled="hidden"
      />
      <FormError :show="step1.rules.month" message="Seleccione el mes" />
      <BasicLabel label="Año *" />
      <BorderSelect
        v-model="step1.data.year"
        label="Año *"
        :options="step1.years"
        :disabled="hidden"
      />
      <FormError :show="step1.rules.year" message="Seleccione el año" />
      <BasicLabel label="Saldo Inicial del Mes *" />
      <BorderInput
        v-model="step1.data.actualBalance"
        label="Saldo Inicial del Mes *"
        type="number"
        :disabled="hidden"
      />
      <FormError
        :show="step1.rules.actualBalance"
        message="Ingrese el saldo inicial del mes"
      />
      <BasicLabel label="Fecha de Vencimiento de pago *" />
      <BorderDate
        v-model="step1.data.expirationDate"
        label="Fecha de Vencimiento de pago *"
        :disabled="hidden"
      />
      <FormError
        :show="step1.rules.expirationDate"
        message="Seleccione una fecha"
      />
      <BasicLabel label="Comentarios" />
      <OutlinedTextArea v-model="step1.data.comment" :disabled="hidden" />
      <FormError />
      <PrimaryButton v-if="!hidden" label="Siguiente" :click="onNext" />
    </div>

    <!-- Cierre Detalle de Gastos Comunes -->
    <div v-if="stepSelected === 2" class="container-form">
      <BasicTitle title="Cierre Detalle de Gastos Comunes" />
      <FormError />
      <div>
        <Actions v-if="!hidden" :items="step2.actions" :right="true" />
        <MobileTable
          label="Detalle de gastos comunes por mes"
          :columns="step2.columns"
          :data="step2.list"
          v-model:selection="step2.selected"
          :rows="50"
        />
        <DesktopTable
          label="Detalle de gastos comunes por mes"
          :columns="step2.columns"
          :data="step2.list"
          v-model:selection="step2.selected"
          :rows="50"
        />
      </div>
      <FormError />
      <PrimaryButton v-if="!hidden" label="Siguiente" :click="onNext" />
      <BasicModal v-model:visible="step2.showDialog">
        <template v-slot:dialog>
          <BasicTitle title="Modificar detalle de gastos comunes" />
          <BasicSubtitle
            subtitle="Los campos señalados con (*) son obligatorios."
          />
          <div class="container-form">
            <BasicLabel label="No. Torre *" />
            <BorderInput v-model="step2.data.tower" label="No. Torre *" />
            <FormError :show="step2.rules.tower" />
            <BasicLabel label="No. Apartamento *" />
            <BorderInput
              v-model="step2.data.apartment"
              label="No. Apartamento *"
            />
            <FormError :show="step2.rules.apartment" />
            <BasicLabel label="Propietario o Inquilino (P o I) *" />
            <BorderSelect
              v-model="step2.data.owner"
              label="Propietario o Inquilino (P o I) *"
              :options="step2.owners"
            />
            <FormError :show="step2.rules.owner" />
            <BasicLabel label="Saldo mes anterior *" />
            <BorderInput
              v-model="step2.data.prevBalance"
              label="Saldo mes anterior *"
              type="number"
            />
            <FormError :show="step2.rules.prevBalance" />
            <BasicLabel label="Pagos *" />
            <BorderInput
              v-model="step2.data.payments"
              label="Pagos *"
              type="number"
            />
            <FormError :show="step2.rules.payments" />
            <BasicLabel label="Saldo mes actual *" />
            <BorderInput
              v-model="step2.data.balance"
              label="Saldo mes actual *"
              type="number"
              :disabled="true"
            />
            <FormError :show="step2.rules.balance" />
            <BasicLabel label="Recargo o deuda *" />
            <BorderInput
              v-model="step2.data.surcharge"
              label="Recargo o deuda *"
              type="number"
            />
            <FormError :show="step2.rules.surcharge" />
            <BasicLabel label="Gastos comunes *" />
            <BorderInput
              v-model="step2.data.commonExpenses"
              label="Gastos comunes *"
              type="number"
            />
            <FormError :show="step2.rules.commonExpenses" />
            <BasicLabel label="Cuota extra *" />
            <BorderInput
              v-model="step2.data.extraFee"
              label="Cuota extra *"
              type="number"
            />
            <FormError :show="step2.rules.extraFee" />
            <BasicLabel label="Fondo de reserva *" />
            <BorderInput
              v-model="step2.data.reserveFound"
              label="Fondo de reserva *"
              type="number"
            />
            <FormError :show="step2.rules.reserveFound" />
            <BasicLabel label="Áreas comunes *" />
            <BorderInput
              v-model="step2.data.commonAreas"
              label="Áreas comunes *"
              type="number"
            />
            <FormError :show="step2.rules.commonAreas" />
            <BasicLabel label="Total a pagar del mes *" />
            <BorderInput
              v-model="step2.data.totalPeriod"
              label="Total a pagar del mes *"
              type="number"
              :disabled="true"
            />
            <FormError :show="step2.rules.totalPeriod" />
            <BasicLabel label="Total a pagar del mes *" />
            <BorderInput
              v-model="step2.data.totalDebt"
              label="Total a pagar del mes *"
              type="number"
              :disabled="true"
            />
            <FormError :show="step2.rules.totalDebt" />
            <PrimaryButton
              :disabled="!step2.list.length"
              label="Guardar"
              :click="step2.onSave"
            />
          </div>
        </template>
      </BasicModal>
    </div>

    <!-- Cierre Detalle de Gastos del Complejo -->
    <div v-if="stepSelected === 3" class="container-form">
      <BasicTitle title="Cierre Detalle de Gastos del Complejo" />
      <FormError />
      <div>
        <div class="step3-container">
          <BasicSubtitle
            :error="step3.negative1"
            :title="`Saldo Inicial: ${getActualBalance(null)}`"
          />
          <BasicSubtitle
            :error="step3.negative"
            :title="`Saldo Final: ${getActualBalance(step3.list)}`"
          />
        </div>
        <Actions v-if="!hidden" :items="step3.actions" :right="true" />
        <MobileTable
          label="Detalle de gastos comunes por mes"
          :columns="step3.columns"
          :data="step3.list"
          :rows="50"
          v-model:selection="step3.selected"
        />
        <DesktopTable
          label="Detalle de gastos comunes por mes"
          :columns="step3.columns"
          :data="step3.list"
          :rows="50"
          v-model:selection="step3.selected"
        />
      </div>
      <FormError />
      <PrimaryButton v-if="!hidden" label="Siguiente" :click="onNext" />
      <BasicModal v-model:visible="step3.showDialog">
        <template v-slot:dialog>
          <BasicTitle title="Modificar movimiento" />
          <BasicSubtitle
            subtitle="Los campos señalados con (*) son obligatorios."
          />
          <div class="container-form">
            <BasicLabel label="Rubro *" />
            <BorderInput v-model="step3.data.heading" label="Rubro *" />
            <FormError :show="step3.rules.heading" />
            <BasicLabel label="Detalle *" />
            <BorderInput v-model="step3.data.detail" label="Detalle *" />
            <FormError :show="step3.rules.detail" />
            <BasicLabel label="Fecha *" />
            <BorderDate v-model="step3.data.date" label="Fecha *" />
            <FormError />
            <BasicLabel label="Ingreso *" />
            <BorderInput
              v-model="step3.data.income"
              label="Ingreso *"
              type="number"
            />
            <FormError :show="step3.rules.income" />
            <BasicLabel label="Egreso *" />
            <BorderInput
              v-model="step3.data.expense"
              label="Egreso *"
              type="number"
            />
            <FormError :show="step3.rules.expense" />
            <BasicLabel label="Total *" />
            <BorderInput
              v-model="step3.data.total"
              label="Total *"
              type="number"
              :disabled="true"
            />
            <FormError :show="step3.rules.total" />
            <PrimaryButton
              :disabled="!step3.list.length"
              label="Guardar"
              :click="step3.onSave"
            />
          </div>
        </template>
      </BasicModal>
    </div>

    <!-- Generación de documentos -->
    <div v-if="stepSelected === 4" class="container-form">
      <BasicTitle title="Generación de documentos" />
      <!-- <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      /> -->
      <DefaultCheck :list="step4.documentsList" />
      <BorderFile :v-model="step4.selectedDocuments" @select="onSelectFiles" />
      <FormError />
      <PrimaryButton v-if="!hidden" label="Generar" :click="onNext" />
    </div>

    <!-- Envío de Documentos al Residente -->
    <div v-if="stepSelected === 5" class="container-form">
      <BasicTitle title="Envío de Documentos al Residente" />
      <!-- <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      /> -->
      <FormError />
      <PrimaryButton v-if="!hidden" label="Enviar" :click="onSend" />
    </div>
  </div>
</template>

<script>
import BasicModal from "../../widgets/modal/BasicModal";
import readXlsxFile from "read-excel-file";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import DesktopSteps from "../../widgets/steps/DesktopSteps";
import BorderDate from "../../widgets/calendar/BorderDate";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import BasicLabel from "../../widgets/label/BasicLabel";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import store from "../../store";
import {
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  reactive,
  ref,
  watchEffect,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  openMode,
  actions,
  dates,
  currency,
  aws,
  validation,
} from "../../constants";
import DefaultCheck from "../../widgets/check/DefaultCheck.vue";

import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    DesktopSteps,
    BorderDate,
    OutlinedTextArea,
    BasicLabel,
    Actions,
    DesktopTable,
    MobileTable,
    DefaultCheck,
    BasicModal,
    BorderFile,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const hidden = ref(false);

    const lastStep = ref(1);
    const stepSelected = ref(1);
    const stepsDisabled = ref([]);
    const steps = [
      { id: 1, title: "Introducción" },
      { id: 2, title: "Cierre Detalle de Gastos Comunes" },
      { id: 3, title: "Cierre Detalle de Gastos del Complejo" },
      { id: 4, title: "Generación de documentos" },
      { id: 5, title: "Envío de Documentos al Residente" },
    ];
    const onSelectStep = async (step) => {
      stepSelected.value = step.id;
      if (step.id === 2) {
        store.commit("setLoading", true);
        const detail = await store.dispatch(
          actions.commonExpenseActions.findAllDetail,
          step1.data.id
        );
        store.commit("setLoading", false);
        if (detail.ok) {
          step2.list = detail.commonExpensesDetail;
        } else {
          alert(detail.message);
        }
      } else if (step.id === 3) {
        store.commit("setLoading", true);
        const movement = await store.dispatch(
          actions.commonExpenseActions.findAllMovement,
          step1.data.id
        );
        store.commit("setLoading", false);
        if (movement.ok) {
          step3.list = movement.movements;
        } else {
          alert(movement.message);
        }
      }
    };

    const today = new Date();

    const getYears = () => {
      const year = new Date().getFullYear();
      const years = [];
      for (let i = year - 5; i <= year + 5; i++) years.push({ id: i, name: i });
      return years;
    };

    const getMonths = () =>
      dates.months.map((month, idx) => ({ id: idx, name: month }));

    const step1 = reactive({
      months: getMonths(),
      years: getYears(),
      data: {
        id: -1,
        actualBalance: "",
        month: today.getMonth(),
        year: today.getFullYear(),
        expirationDate: "",
        comment: "",
      },
      rules: {
        actualBalance: false,
        expirationDate: false,
      },
    });

    const validateStep1 = () => {
      let isValid = true;
      if (!step1.data.actualBalance) {
        step1.rules.actualBalance = true;
        isValid = false;
      }
      if (!step1.data.expirationDate) {
        step1.rules.expirationDate = true;
        isValid = false;
      }

      return isValid;
    };

    const validateStep2 = (flag) => {
      let isValid = true;

      if (!flag) {
        if (!step2.data.tower) {
          step2.rules.tower = true;
          isValid = false;
        }
        if (!step2.data.apartment) {
          step2.rules.apartment = true;
          isValid = false;
        }
        if (!step2.data.owner) {
          step2.rules.owner = true;
          isValid = false;
        }
        if (!step2.data.prevBalance && step2.data.prevBalance != 0) {
          step2.rules.prevBalance = true;
          isValid = false;
        }
        if (!step2.data.payments && step2.data.payments != 0) {
          step2.rules.payments = true;
          isValid = false;
        }
        if (!step2.data.balance && step2.data.balance != 0) {
          step2.rules.balance = true;
          isValid = false;
        }
        if (!step2.data.surcharge && step2.data.surcharge != 0) {
          step2.rules.surcharge = true;
          isValid = false;
        }
        if (!step2.data.commonExpenses && step2.data.commonExpenses != 0) {
          step2.rules.commonExpenses = true;
          isValid = false;
        }
        if (!step2.data.extraFee && step2.data.extraFee != 0) {
          step2.rules.extraFee = true;
          isValid = false;
        }
        if (!step2.data.reserveFound && step2.data.reserveFound != 0) {
          step2.rules.reserveFound = true;
          isValid = false;
        }
        if (!step2.data.commonAreas && step2.data.commonAreas != 0) {
          step2.rules.commonAreas = true;
          isValid = false;
        }
        if (!step2.data.totalPeriod && step2.data.totalPeriod != 0) {
          step2.rules.totalPeriod = true;
          isValid = false;
        }
        if (!step2.data.totalDebt && step2.data.totalDebt != 0) {
          step2.rules.totalDebt = true;
          isValid = false;
        }
      } else if (!step2.list.length) {
        isValid = false;
      } else {
        /* const finded = step2.list.find(
          (item) =>
            currency.convertCurrencyToNumber(item.prevBalance) -
              currency.convertCurrencyToNumber(item.payments) !==
              currency.convertCurrencyToNumber(item.balance) ||
            currency.convertCurrencyToNumber(item.balance) +
              currency.convertCurrencyToNumber(item.surcharge) +
              currency.convertCurrencyToNumber(item.commonExpenses) +
              currency.convertCurrencyToNumber(item.extraFee) +
              currency.convertCurrencyToNumber(item.reserveFound) +
              currency.convertCurrencyToNumber(item.commonAreas) !==
              currency.convertCurrencyToNumber(item.totalDebt) ||
            currency.convertCurrencyToNumber(item.totalPeriod) !==
              currency.convertCurrencyToNumber(item.commonExpenses) +
                currency.convertCurrencyToNumber(item.extraFee) +
                currency.convertCurrencyToNumber(item.reserveFound) +
                currency.convertCurrencyToNumber(item.commonAreas)
        );
        if (finded) isValid = false; */
      }

      return isValid;
    };

    const validateStep3 = (flag) => {
      let isValid = true;
      if (!flag) {
        if (!step3.data.heading) {
          step3.rules.heading = true;
          isValid = false;
        }
        if (!step3.data.date) {
          step3.rules.date = true;
          isValid = false;
        }
        if (!step3.data.income && step3.data.income != 0) {
          step3.rules.income = true;
          isValid = false;
        }
        if (!step3.data.expense && step3.data.expense != 0) {
          step3.rules.expense = true;
          isValid = false;
        }
        if (!step3.data.total && step3.data.total != 0) {
          step3.rules.total = true;
          isValid = false;
        }
      } else if (!step3.list.length) {
        isValid = false;
      } else {
        /* const finded = step3.list.find(
          (item) =>
            currency.convertCurrencyToNumber(item.income) -
              currency.convertCurrencyToNumber(item.expense) !==
            currency.convertCurrencyToNumber(item.total)
        );
        if (finded) isValid = false; */
      }

      return isValid;
    };

    const step2OnSave = () => {
      if (!validateStep2(false)) return;

      step2.selected = [];
      step2.list = step2.list.map((item) =>
        item.id === step2.data.id
          ? {
              id: "",
              tower: step2.data.tower,
              owner: step2.data.owner,
              apartment: step2.data.apartment,
              prevBalance: +step2.data.prevBalance,
              payments: +step2.data.payments,
              balance: +step2.data.balance,
              surcharge: +step2.data.surcharge,
              commonExpenses: +step2.data.commonExpenses,
              extraFee: +step2.data.extraFee,
              reserveFound: +step2.data.reserveFound,
              commonAreas: +step2.data.commonAreas,
              totalPeriod: +step2.data.totalPeriod,
              totalDebt: +step2.data.totalDebt,
            }
          : item
      );
      step2.data = {
        id: "",
        owner: "",
        tower: "",
        apartment: "",
        prevBalance: "",
        payments: "",
        balance: "",
        surcharge: "",
        commonExpenses: "",
        extraFee: "",
        reserveFound: "",
        commonAreas: "",
        totalPeriod: "",
        totalDebt: "",
      };
      step2.showDialog = false;
    };

    const step3OnSave = () => {
      if (!validateStep3(false)) return;

      step3.selected = [];
      step3.list = step3.list.map((item) =>
        item.id === step3.data.id
          ? {
              commonExpenseId: item.commonExpenseId,
              id: item.id,
              date: step3.data.date,
              detail: step3.data.detail,
              heading: step3.data.heading,
              income: +step3.data.income,
              expense: +step3.data.expense,
              total: +step3.data.total,
            }
          : item
      );
      step3.data = {
        id: "",
        heading: "",
        detail: "",
        date: "",
        income: "",
        expense: "",
        total: "",
      };
      step3.showDialog = false;
    };

    const step2 = reactive({
      list: [],
      selected: [],
      showDialog: false,
      onSave: step2OnSave,
      owners: [
        { id: "P", name: "Propietario" },
        { id: "I", name: "Inquilino" },
      ],
      data: {
        id: "",
        tower: "",
        apartment: "",
        owner: "",
        prevBalance: "",
        payments: "",
        balance: "",
        surcharge: "",
        commonExpenses: "",
        extraFee: "",
        reserveFound: "",
        commonAreas: "",
        totalPeriod: "",
        totalDebt: "",
      },
      rules: {
        tower: false,
        apartment: false,
        owner: false,
        prevBalance: false,
        payments: false,
        balance: false,
        surcharge: false,
        commonExpenses: false,
        extraFee: false,
        reserveFound: false,
        commonAreas: false,
        totalPeriod: false,
        totalDebt: false,
      },
      schema: {
        "No. torre": {
          prop: "tower",
          type: String,
        },
        "No. apto": {
          prop: "apartment",
          type: String,
        },
        "Propietario o Inquilino (P o I)": {
          prop: "owner",
          type: String,
        },
        "Saldo mes anterior": {
          prop: "prevBalance",
          type: Number,
        },
        Pagos: {
          prop: "payments",
          type: Number,
        },
        "Saldo mes actual": {
          prop: "balance",
          type: Number,
        },
        "Recargo o deuda": {
          prop: "surcharge",
          type: Number,
        },
        "Gastos comunes": {
          prop: "commonExpenses",
          type: Number,
        },
        "Cuota extra": {
          prop: "extraFee",
          type: Number,
        },
        "Fondo reserva": {
          prop: "reserveFound",
          type: Number,
        },
        "Áreas comunes": {
          prop: "commonAreas",
          type: Number,
        },
        "Total del período": {
          prop: "totalPeriod",
          type: Number,
        },
        "Total a pagar del mes": {
          prop: "totalDebt",
          type: Number,
        },
      },
      columns: [
        {
          field: "tower",
          header: "No. torre",
          sort: true,
        },
        {
          field: "apartment",
          header: "No. apto",
          sort: true,
        },
        {
          field: "owner",
          header: "Propietario o Inquilino (P o I)",
          sort: true,
        },
        {
          field: "prevBalance",
          header: "Saldo mes anterior",
          sort: true,
          currency: true,
        },
        {
          field: "payments",
          header: "Pagos",
          sort: true,
          currency: true,
        },
        {
          field: "balance",
          header: "Saldo mes actual",
          sort: true,
          currency: true,
        },
        {
          field: "surcharge",
          header: "Recargo o deuda",
          sort: true,
          currency: true,
        },
        {
          field: "commonExpenses",
          header: "Gastos comunes",
          sort: true,
          currency: true,
        },
        {
          field: "extraFee",
          header: "Cuota extra",
          sort: true,
          currency: true,
        },
        {
          field: "reserveFound",
          header: "Fondo reserva",
          sort: true,
          currency: true,
        },
        {
          field: "commonAreas",
          header: "Áreas comunes",
          sort: true,
          currency: true,
        },
        {
          field: "totalPeriod",
          header: "Total del período",
          sort: true,
          currency: true,
        },
        {
          field: "totalDebt",
          header: "Total a pagar del mes",
          sort: true,
          currency: true,
        },
      ],
      actions: [
        {
          name: "Modificar",
          action: () => {
            if (step2.selected.length !== 1) {
              alert("Debe seleccionar un registro!");
            } else {
              step2.data.id = step2.selected[0].id;
              step2.data.tower = step2.selected[0].tower;
              step2.data.apartment = step2.selected[0].apartment;
              step2.data.prevBalance = step2.selected[0].prevBalance;
              step2.data.payments = step2.selected[0].payments;
              step2.data.balance = step2.selected[0].balance;
              step2.data.surcharge = step2.selected[0].surcharge;
              step2.data.commonExpenses = step2.selected[0].commonExpenses;
              step2.data.extraFee = step2.selected[0].extraFee;
              step2.data.reserveFound = step2.selected[0].reserveFound;
              step2.data.commonAreas = step2.selected[0].commonAreas;
              step2.data.totalPeriod = step2.selected[0].totalPeriod;
              step2.data.totalDebt = step2.selected[0].totalDebt;
              step2.showDialog = true;
            }
          },
          hidden: true,
        },
        {
          name: "Descargar Plantilla",
          action: async () => {
            await store.commit("setLoading", true);
            await store.dispatch(actions.commonExpenseActions.templateDetail);
            await store.commit("setLoading", false);
          },
          hidden: true,
        },
        {
          name: "Importar Datos",
          action: () => {
            const input = document.getElementById("upload-detail");
            input.addEventListener("change", onChangeDetail, true);
            input.click();
          },
          hidden: true,
        },
      ],
    });

    const step3 = reactive({
      list: [],
      selected: [],
      showDialog: false,
      onSave: step3OnSave,
      negative: false,
      negative1: false,
      data: {
        id: "",
        heading: "",
        detail: "",
        date: "",
        income: "",
        expense: "",
        total: "",
      },
      rules: {
        id: false,
        heading: false,
        date: false,
        income: false,
        expense: false,
        total: false,
      },
      schema: {
        Rubro: {
          prop: "heading",
          type: String,
        },
        Detalle: {
          prop: "detail",
          type: String,
        },
        Fecha: {
          prop: "date",
          type: String,
        },
        Ingreso: {
          prop: "income",
          type: Number,
        },
        Egreso: {
          prop: "expense",
          type: Number,
        },
        Total: {
          prop: "total",
          type: Number,
        },
      },
      columns: [
        {
          field: "heading",
          header: "Rubro",
          sort: true,
        },
        {
          field: "detail",
          header: "Detalle",
          sort: true,
        },
        {
          field: "date",
          header: "Fecha",
          sort: true,
          date: true,
        },
        {
          field: "income",
          header: "Ingreso",
          sort: true,
          currency: true,
        },
        {
          field: "expense",
          header: "Egreso",
          sort: true,
          currency: true,
        },
        {
          field: "total",
          header: "Total",
          sort: true,
          currency: true,
        },
      ],
      actions: [
        {
          name: "Modificar",
          action: () => {
            if (step3.selected.length !== 1) {
              alert("Debe seleccionar un registro!");
            } else {
              step3.data.id = step3.selected[0].id;
              step3.data.heading = step3.selected[0].heading;
              step3.data.detail = step3.selected[0].detail;
              step3.data.date = new Date(step3.selected[0].date);
              step3.data.income = step3.selected[0].income;
              step3.data.expense = step3.selected[0].expense;
              step3.data.total = step3.selected[0].total;
              step3.showDialog = true;
            }
          },
          hidden: true,
        },
        {
          name: "Descargar Plantilla",
          action: async () => {
            await store.commit("setLoading", true);
            await store.dispatch(actions.commonExpenseActions.templateMovement);
            await store.commit("setLoading", false);
          },
          hidden: true,
        },
        {
          name: "Importar Datos",
          action: () => {
            const input = document.getElementById("upload-movement");
            input.addEventListener("change", onChangeMovement, true);
            input.click();
          },
          hidden: true,
        },
      ],
    });

    const step4 = reactive({
      selectedDocuments: [],
      documentsList: [
        {
          id: 0,
          status: true,
          title: "Documento de Gastos comunes por apartamento",
        },
        {
          id: 1,
          status: true,
          title: "Documento de Detalle de movimientos del complejo",
        },
      ],
    });

    const onSelectFiles = (ev) => {
      step4.selectedDocuments = ev.files;
    };

    const onChangeDetail = () => {
      const input = document.getElementById("upload-detail");
      readXlsxFile(input.files[0], { schema: step2.schema }).then(
        ({ rows, errors }) => {
          if (!rows.length || errors.length)
            store.state.toast.add({
              severity: "error",
              summary: "",
              detail: "Error al importar los datos",
              life: 5000,
            });
          step2.list = [];
          processFile(rows, step2.list, step2.columns);
        }
      );
    };
    const onChangeMovement = () => {
      const input = document.getElementById("upload-movement");
      readXlsxFile(input.files[0], {
        schema: step3.schema,
      }).then(({ rows, errors }) => {
        if (!rows.length || errors.length)
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: "Error al importar los datos",
            life: 5000,
          });
        step3.list = [];
        processFile(rows, step3.list, step3.columns);
      });
    };

    const getActualBalance = (list) => {
      let balance = +step1.data.actualBalance;
      if (!list) {
        if (balance < 0) step3.negative1 = true;
        else step3.negative1 = false;
      } else {
        if (list.length) list.forEach((item) => (balance += item.total));
        if (balance < 0) step3.negative = true;
        else step3.negative = false;
      }

      return currency.convertNumberToCurrency(balance);
    };

    const onNext = async () => {
      switch (stepSelected.value) {
        case 1:
          await executeStep1();
          break;
        case 2:
          await executeStep2();
          break;
        case 3:
          await executeStep3();
          break;
        case 4:
          await executeStep4();
          break;
        default:
          break;
      }
    };

    const processFile = (rows, list, columns) => {
      for (let i = 0; i < rows.length; i++) {
        rows[i].id = (i + 1) * -1;
        columns.forEach((el, idx) => {
          if (el.date) {
            rows[i][el.field] = dates.convertStringToDate(rows[i][el.field]);
          }
        });
        list.push(rows[i]);
      }
      if (stepSelected.value === 2) {
        const input = document.getElementById("upload-detail");
        input.removeEventListener("change", onChangeDetail, true);
      } else if (stepSelected.value === 3) {
        const input = document.getElementById("upload-movement");
        input.removeEventListener("change", onChangeMovement, true);
      }
    };

    const executeStep1 = async () => {
      if (!validateStep1()) return;

      store.commit("setLoading", true);
      const tempDate = new Date();
      tempDate.setMonth(step1.data.month);
      tempDate.setFullYear(step1.data.year);
      tempDate.setHours(0, 0, 0, 0);

      const request = {
        buildingId: store.state.general.selectedBuilding,
        id: step1.data.id,
        period: new Date(tempDate).getTime(),
        expirationDate: new Date(step1.data.expirationDate).getTime(),
        comment: step1.data.comment,
        commonExpenseStatusId: 1,
        actualBalance: +step1.data.actualBalance,
      };
      const response = await store.dispatch(
        actions.commonExpenseActions.create,
        request
      );
      if (response.ok) {
        step1.data.id = response.commonExpenseId;
        stepSelected.value = stepSelected.value + 1;
        if (lastStep.value < stepSelected.value) {
          lastStep.value = lastStep.value + 1;
        }
        const detail = await store.dispatch(
          actions.commonExpenseActions.findAllDetail,
          step1.data.id
        );
        store.commit("setLoading", false);
        if (detail.ok) {
          step2.list = detail.commonExpensesDetail;
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: detail.message,
            life: 5000,
          });
        }
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const executeStep2 = async () => {
      if (!validateStep2(true)) return;

      store.commit("setLoading", true);
      const request = {
        buildingId: store.state.general.selectedBuilding,
        commonExpenseId: step1.data.id,
        data: step2.list.map((item) => ({
          id: item.id,
          tower: item.tower,
          apartment: item.apartment,
          owner: item.owner,
          prevBalance: item.prevBalance,
          prevPayment: item.payments,
          balance: item.balance,
          surcharge: item.surcharge,
          commonExpenses: item.commonExpenses,
          extraFee: item.extraFee,
          reserveFound: item.reserveFound,
          commonAreas: item.commonAreas,
          totalPeriod: item.totalPeriod,
          totalDebt: item.totalDebt,
        })),
      };
      const response = await store.dispatch(
        actions.commonExpenseActions.createDetail,
        request
      );
      store.commit("setLoading", false);
      if (response.ok) {
        stepSelected.value = stepSelected.value + 1;
        if (lastStep.value < stepSelected.value) {
          lastStep.value = lastStep.value + 1;
        }
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
      store.commit("setLoading", true);
      const movement = await store.dispatch(
        actions.commonExpenseActions.findAllMovement,
        step1.data.id
      );
      store.commit("setLoading", false);
      if (movement.ok) {
        step3.list = movement.movements;
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: movement.message,
          life: 5000,
        });
      }
    };

    const executeStep3 = async () => {
      if (!validateStep3(true)) return;

      store.commit("setLoading", true);
      const request = {
        buildingId: store.state.general.selectedBuilding,
        commonExpenseId: step1.data.id,
        data: step3.list.map((item) => ({
          id: item.id,
          heading: item.heading,
          detail: item.detail,
          date: new Date(item.date).getTime(),
          charge: item.income,
          debt: item.expense,
          total: item.total,
        })),
      };
      const response = await store.dispatch(
        actions.commonExpenseActions.createMovement,
        request
      );
      store.commit("setLoading", false);
      if (response.ok) {
        stepSelected.value = stepSelected.value + 1;
        if (lastStep.value < stepSelected.value) {
          lastStep.value = lastStep.value + 1;
        }
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const executeStep4 = async () => {
      store.commit("setLoading", true);
      const request = {
        buildingId: store.state.general.selectedBuilding,
        commonExpenseId: step1.data.id,
      };
      const response = await store.dispatch(
        actions.commonExpenseActions.generate,
        request
      );
      if (response.ok) {
        const response2 = await store.dispatch("GENERAL_GET_SIGNED_URL", {
          buildingId: store.state.general.selectedBuilding,
          commonExpenseId: step1.data.id,
        });
        if (response2.ok) {
          const finish = () => {
            store.commit("setLoading", false);
            stepSelected.value = stepSelected.value + 1;
            if (lastStep.value < stepSelected.value) {
              lastStep.value = lastStep.value + 1;
            }
          };
          let count = 0;
          const { length } = step4.selectedDocuments;

          if (count === length) finish();

          const building = store.state.auth.user.buildings.find(
            (item) => item.id === store.state.general.selectedBuilding
          );

          step4.selectedDocuments.forEach(async (doc) => {
            const name = `${building.name}-${doc.name}`;
            const key = `${response2.path}${name}`;
            const uploaded = async () => {
              const savedDoc = await store.dispatch(
                actions.commonExpenseActions.saveDocument,
                {
                  path: response2.path,
                  name: name
                    .split(".")
                    .slice(0, -1)
                    .join("."),
                  ext: validation.extensionRegex.exec(name)[0],
                  buildingId: building.id,
                  entityId: step1.data.id,
                }
              );

              if (!savedDoc.ok) {
                store.state.toast.add({
                  severity: "error",
                  summary: "",
                  detail: savedDoc.message,
                  life: 5000,
                });
              }

              count += 1;
              if (count === length) finish();
            };
            aws.uploadFile(key, doc, uploaded);
          });
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response2.message,
            life: 5000,
          });
        }
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const onSend = async () => {
      store.commit("setLoading", true);
      const request = {
        buildingId: store.state.general.selectedBuilding,
        commonExpenseId: step1.data.id,
      };
      const response = await store.dispatch(
        actions.commonExpenseActions.send,
        request
      );
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
        router.push("/cierres/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/cierres/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.commonExpense.selectedClosures[0];
        const tempDate = new Date(selected.period);

        lastStep.value = selected.statusId + 1;
        stepSelected.value = selected.statusId;
        step1.data.id = selected.id;
        step1.data.actualBalance = selected.actualBalance;
        step1.data.month = tempDate.getMonth();
        step1.data.year = tempDate.getFullYear();
        step1.data.expirationDate = new Date(selected.expirationDate);
        step1.data.comment = selected.comment;
      } else if (store.state.openMode === openMode.DETAIL) {
        const selected = store.state.commonExpense.selectedClosures[0];
        const tempDate = new Date(selected.period);

        lastStep.value = selected.statusId > 3 ? 3 : selected.statusId;
        stepSelected.value = 1;
        step1.data.id = selected.id;
        step1.data.actualBalance = selected.actualBalance;
        step1.data.month = tempDate.getMonth();
        step1.data.year = tempDate.getFullYear();
        step1.data.expirationDate = new Date(selected.expirationDate);
        step1.data.comment = selected.comment;
        hidden.value = true;
      }
    });

    onMounted(async () => {
      if (store.state.openMode === openMode.MODIFY) {
        stepSelected.value = lastStep.value;
        if (stepSelected.value === 2) {
          store.commit("setLoading", true);
          const detail = await store.dispatch(
            actions.commonExpenseActions.findAllDetail,
            step1.data.id
          );
          store.commit("setLoading", false);
          if (detail.ok) {
            step2.list = detail.commonExpensesDetail;
          } else {
            store.state.toast.add({
              severity: "error",
              summary: "",
              detail: detail.message,
              life: 5000,
            });
          }
        } else if (stepSelected.value === 3) {
          store.commit("setLoading", true);
          const movement = await store.dispatch(
            actions.commonExpenseActions.findAllMovement,
            step1.data.id
          );
          store.commit("setLoading", false);
          if (movement.ok) {
            step3.list = movement.movements;
          } else {
            store.state.toast.add({
              severity: "error",
              summary: "",
              detail: movement.message,
              life: 5000,
            });
          }
        }
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      /** Step 1 */
      if (step1.data.actualBalance) step1.rules.actualBalance = false;
      if (step1.data.expirationDate) step1.rules.expirationDate = false;
      /** Step 2 */
      if (step2.data.tower) step2.rules.tower = false;
      if (step2.data.apartment) step2.rules.apartment = false;
      if (step2.data.owner) step2.rules.owner = false;
      if (step2.data.prevBalance || step2.data.prevBalance == 0)
        step2.rules.prevBalance = false;
      if (step2.data.payments || step2.data.payments == 0)
        step2.rules.payments = false;
      step2.data.balance =
        Number(step2.data.prevBalance) - Number(step2.data.payments);
      if (step2.data.balance || step2.data.balance == 0)
        step2.rules.balance = false;
      if (step2.data.surcharge || step2.data.surcharge == 0)
        step2.rules.surcharge = false;
      if (step2.data.commonExpenses || step2.data.commonExpenses == 0)
        step2.rules.commonExpenses = false;
      if (step2.data.extraFee || step2.data.extraFee == 0)
        step2.rules.extraFee = false;
      if (step2.data.reserveFound || step2.data.reserveFound == 0)
        step2.rules.reserveFound = false;
      if (step2.data.commonAreas || step2.data.commonAreas == 0)
        step2.rules.commonAreas = false;
      step2.data.totalPeriod =
        Number(step2.data.commonExpenses) +
        Number(step2.data.extraFee) +
        Number(step2.data.reserveFound) +
        Number(step2.data.commonAreas);
      if (step2.data.totalPeriod || step2.data.totalPeriod == 0)
        step2.rules.totalPeriod = false;
      step2.data.totalDebt =
        Number(step2.data.balance) +
        Number(step2.data.surcharge) +
        Number(step2.data.totalPeriod);
      if (step2.data.totalDebt || step2.data.totalDebt == 0)
        step2.rules.totalDebt = false;
      /** Step 3 */
      if (step3.data.heading) step3.rules.heading = false;
      if (step3.data.date) step3.rules.date = false;
      if (step3.data.income || step3.data.icome == 0)
        step3.rules.income = false;
      if (step3.data.expense || step3.data.expense == 0)
        step3.rules.expense = false;
      step3.data.total = Number(step3.data.income) - Number(step3.data.expense);
      if (step3.data.total) step3.rules.total = false;
    });

    return {
      steps,
      lastStep,
      stepSelected,
      stepsDisabled,
      onSelectStep,
      onNext,
      onSend,
      step1,
      step2,
      step3,
      step4,
      store,
      getActualBalance,
      hidden,
      onSelectFiles,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.step3-container {
  display: flex;
  justify-content: space-around;
}
.upload {
  display: none;
}
</style>
