<template>
  <div class="check">
    <div v-for="item in list" :key="item.id" class="check-container">
      <i :class="`pi ${item.status ? 'pi-check' : 'pi-times'}`"></i>
      <p :class="`${!item.status && 'status-false'}`">{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.check {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 1rem 0;
}
.check-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.check-container > i {
  font-size: 2rem;
  color: var(--primary-dark);
  margin-right: 1rem;
}
.check-container > p {
  color: var(--primary-dark);
}
.status-false {
  text-decoration: line-through;
}
</style>
