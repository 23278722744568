<template>
  <div class="step">
    <div class="step-container">
      <div v-for="step in steps" :key="step.id" class="step-item">
        <div
          @click="
            () =>
              step.id !== selected &&
              step.id <= last &&
              !disabled.includes(step.id) &&
              onSelect(step)
          "
          :class="
            `step-number ${step.id === selected &&
              !disabled.includes(step.id) &&
              'step-number-selected'} ${(step.id > last ||
              disabled.includes(step.id)) &&
              'step-number-disabled'}`
          "
        >
          <p>{{ step.id }}</p>
        </div>
        <div
          :class="
            `step-title ${step.id === selected &&
              !disabled.includes(step.id) &&
              'step-title-selected'} ${(step.id > last ||
              disabled.includes(step.id)) &&
              'step-title-disabled'}`
          "
        >
          <p>{{ step.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: [
        { id: 1, title: "Step 1" },
        { id: 2, title: "Step 2" },
        { id: 3, title: "Step 3" },
      ],
    },
    selected: {
      type: Number,
      default: 1,
    },
    last: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Array,
      default: [],
    },
    onSelect: {
      type: Function,
      default: (step) => console.log(step),
    },
  },
};
</script>

<style scoped>
.step {
  padding: 0 2rem;
}
.step-container {
  display: flex;
  border-top: 1px solid var(--primary-light);
  justify-content: center;
  margin: 2rem 0 0;
  padding: 0 1rem;
  gap: 1rem;
  min-width: calc(768px - 4rem);
}
.step-item {
  margin: -1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}
.step-number {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--primary-dark);
  border-radius: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.step-number > p {
  font-family: "klavika";
  color: var(--primary-dark);
}
.step-title > p {
  text-align: center;
  margin: 1rem 0 0;
  font-size: 0.8rem;
  color: var(--primary-dark);
}
.step-number-selected {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: default;
}
.step-number-selected > p {
  color: white;
}
.step-title-selected > p {
  color: var(--primary);
}
.step-number-disabled {
  border: 1px solid var(--primary-light);
  cursor: default;
}
.step-number-disabled:hover {
  border: 1px solid var(--primary-light);
  cursor: default;
}
.step-number-disabled > p {
  color: var(--primary-light);
}
.step-title-disabled > p {
  color: var(--primary-light);
}
@media screen and (max-width: 768px) {
  .step {
    width: 500px;
    overflow-x: scroll;
    padding: 0;
  }
}
@media screen and (max-width: 550px) {
  .step {
    width: 350px;
    overflow-x: scroll;
    padding: 0;
  }
}
</style>
